import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

var firebaseConfig = {
  apiKey: "AIzaSyD9mOrFeidfHSowgT5r7VkDH65Im7JzlMw",
  authDomain: "portal-empleado-hr.firebaseapp.com",
  projectId: "portal-empleado-hr",
  storageBucket: "portal-empleado-hr.appspot.com",
  messagingSenderId: "997817922906",
  appId: "1:997817922906:web:8f68f6a1c7cf6a4605af63"
};

app.initializeApp(firebaseConfig);

const firebase = app.firestore()
const auth = app.auth()
const storage = app.storage()

export { firebase, auth, storage }