const Entidad = "OneClickSgr";
// const Entidad = "OneClickSgrSandbox";
const GoogleMapKey = "AIzaSyDwKzicjMvfVsf9SbEAZn9N7i4CxX6YO1I";
const RecaptchaKey = "6LeDOBApAAAAALL9ho_ulk4m4Xr1ZLFlY_0iemZ4";
const UrlApiDynamics = "https://sgroc365api.azurewebsites.net/api/";
const UnidadDeNegocio = "F7ACBC6B-9B47-E911-A9A4-000D3AC1B95D"
const UrlApi = "https://hw365api.azurewebsites.net/"
const CorreoAdministracion = "administracion@avalfertil.com"
//Produccion
const authMail = "admin@avalfertilsgr.com"
const authPass = "Avalfertil.2023$"
//Parametros
const onboardingHabilitado = true
const readOnlyDatos = false
//Sandbox
// const authMail = "admin@sgroneclick.onmicrosoft.com"
// const authPass = "Admin.2022$"

//Localhost
// const UrlApi = "https://localhost:7035/"
// const UrlApiDynamics = "https://localhost:7035/api/";
// const RecaptchaKey = "6LeALiAcAAAAABv5WAeRHUmaf5GfqNSBYn8wTKON";

export {Entidad, GoogleMapKey, RecaptchaKey, UrlApiDynamics, UnidadDeNegocio, UrlApi, authMail, authPass, 
    onboardingHabilitado, readOnlyDatos, CorreoAdministracion}